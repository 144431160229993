import { createContext, useEffect, useState } from "react";

export const UserNameContext = createContext();

export const UserNameProvider = ({ children }) => {
  const [userName, setUserName] = useState("");

  // Função para salvar o userName no localStorage
  const persistUserName = (name) => {
    localStorage.setItem("userName", name);
  };

  // Função para obter o userName do localStorage
  const getUserNameFromLocalStorage = () => {
    const data = localStorage.getItem("userName");
    console.log("data", data);
    if (data) {
      setUserName(data);
    }
  };

  // Função para remover o userName do localStorage
  const dropUserName = () => {
    localStorage.removeItem("userName");
    window.location.reload(); // Recarrega a página após logout
  };

  // Carrega o userName do localStorage na primeira renderização
  useEffect(() => {
    getUserNameFromLocalStorage();
  }, []);

  // Salva o userName no localStorage sempre que ele mudar
  useEffect(() => {
    if (userName) {
      persistUserName(userName);
    }
  }, [userName]);

  return (
    <UserNameContext.Provider
      value={{ userName, setUserName, persistUserName, dropUserName }}
    >
      {children}
    </UserNameContext.Provider>
  );
};
