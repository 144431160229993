import { useNavigate } from "react-router-dom";

const HangupButton = ({
  remoteFeedEl,
  localFeedEl,
  peerConnection,
  callStatus,
  setCallStatus,
}) => {

    const navigate = useNavigate();

  const hangupCall = () => {
    if (peerConnection) {
      const copyCallStatus = { ...callStatus };
      copyCallStatus.current = "complete";
      //user has clicked hang up. pc:
      //close it
      //remove listeners
      //set it to null
      peerConnection.close();
      peerConnection.onicecandidate = null;
      peerConnection.onaddstream = null;
      peerConnection = null;

      //set both video tags to empty
      localFeedEl.current.srcObject = null;
      remoteFeedEl.current.srcObject = null;
      navigate("/", { replace: true }); // navegação suave
      window.location.reload(); // força o recarregamento da página
    }
  };

  if (callStatus.current === "complete") {
    return <></>;
  }

  return (
    <button onClick={hangupCall} className="btn btn-danger hang-up">
      Desligar
    </button>
  );
};

export default HangupButton;
