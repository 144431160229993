import { useContext, useEffect } from "react";
import prepForCall from "../webrtcUtilities/prepForCall";
import socketConnection from "../webrtcUtilities/socketConnection";
import clientSocketListeners from "../webrtcUtilities/clientSocketListeners";
import { useState } from "react";
import createPeerConnection from "../webrtcUtilities/createPeerConn";
import { useSearchParams, useNavigate } from "react-router-dom";
import { UserNameContext } from "../context/UserContext";

const HomeAnswer = ({
  callStatus,
  updateCallStatus,
  setLocalStream,
  setRemoteStream,
  remoteStream,
  peerConnection,
  setPeerConnection,
  localStream,
  //userName,
  //setUserName,
  offerData,
  setOfferData,
}) => {
  const [typeOfCall, setTypeOfCall] = useState();
  const [joined, setJoined] = useState(false);
  const [availableCalls, setAvailableCalls] = useState([]);
  const [myPosition, setMyPosition] = useState();
  const navigate = useNavigate();
  const [localUserName, setLocalUserName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { userName, setUserName, persistUserName, dropUserName } = useContext(UserNameContext);


  const nomeParam = searchParams.get('nome');

  useEffect(() => {
    if (nomeParam) {
      setLocalUserName(nomeParam);
    }
  }, [nomeParam]);

  //called on "Call" or "Answer"
  const initCall = async (typeOfCall) => {
    
    // set localStream and GUM
    await prepForCall(callStatus, updateCallStatus, setLocalStream);
    setTypeOfCall(typeOfCall); //offer or answer
  };

  useEffect(() => {
    if (joined && userName) {
      //const userName = prompt("Enter username");
      //setUserName(userName);

      // Estabelecendo a conexão do socket
      const socket = socketConnection(userName);

      // Atualizar as ofertas disponíveis ao receber o evento
      socket.on("availableOffers", (data) => {
        //console.log("available calls", data);
        setAvailableCalls(data);
      });

      // Atualizar as ofertas quando uma nova oferta estiver aguardando
      socket.on("newOfferAwaiting", (data) => {
        console.log("new offer awaiting: ", data);
        setAvailableCalls((prevCalls) => [...prevCalls, ...data]);
      });

      socket.on("my_position", (data) => {
        console.log("my position: ", data);
        setMyPosition(data);
      });
    }
  }, [joined]);

  //We have media via GUM. setup the peerConnection w/listeners
  useEffect(() => {
    if (callStatus.haveMedia && !peerConnection) {
      // prepForCall has finished running and updated callStatus
      const { peerConnection, remoteStream } = createPeerConnection(
        userName,
        typeOfCall
      );
      setPeerConnection(peerConnection);
      setRemoteStream(remoteStream);
    }
  }, [callStatus.haveMedia]);

  //We know which type of client this is and have PC.
  //Add socketlisteners
  useEffect(() => {
    if (typeOfCall && peerConnection) {
      const socket = socketConnection(userName);
      clientSocketListeners(
        socket,
        typeOfCall,
        callStatus,
        updateCallStatus,
        peerConnection
      );
    }
  }, [typeOfCall, peerConnection]);

  //once remoteStream AND pc are ready, navigate
  useEffect(() => {
    if (remoteStream && peerConnection) {
      navigate(`/${typeOfCall}?token=${Math.random()}`);
    }
  }, [remoteStream, peerConnection]);

  const call = async () => {
    //call related stuff goes here
    initCall("offer");
  };

  const answer = (callData) => {
    //answer related stuff goes here
    initCall("answer");
    setOfferData(callData);
  };

  useEffect(() => {
    if(userName) {
      setJoined(true);
    }
  }, [userName]);

  const checkUsername = (e) => {
    e.preventDefault();
    if (localUserName && localUserName.trim() !== "") {
      setUserName(localUserName); // Salva no contexto
      persistUserName(localUserName); // Persiste no localStorage
      setJoined(true); // Marca como conectado
    }
  };

  if (!joined) {
    return (
      <div className="container d-flex align-items-center justify-content-center min-vh-100">
        <div
          className="card p-5 shadow-lg border-0"
          style={{ maxWidth: "400px", width: "100%" }}
        >
          <form onSubmit={checkUsername}>
            <h2 className="text-center mb-4">Acesso ao sistema</h2>

            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Digite seu nome de usuário"
                required
                value={localUserName}
                onChange={(e) => setLocalUserName(e.target.value)} // Atualiza apenas o estado local
              />
            </div>

            <button type="submit" className="btn btn-primary btn-lg w-100">
              Conectar
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8">
      <div>
        <h1 className="text-2xl font-bold text-center mb-8">{userName}</h1>
        <div>
          <h2 className="text-xl font-semibold mb-6 text-center">
            Pacientes Disponíveis
          </h2>
          <div className="grid grid-cols-1 gap-4">
            {availableCalls.map((callData, i) => (
              <div key={i} className="mb-4">
                <button
                  onClick={() => answer(callData)}
                  className="bg-yellow-400 text-black py-3 px-6 rounded-lg hover:bg-yellow-500 transition w-full shadow-md"
                >
                  {callData.offererUserName} -{" "}
                  {/* {callData.offer.id ?? "current id"} -{" "} */}
                  {callData.currentPosition}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center mt-8">
          {/* Botão de logoff */}
          <button
            onClick={() =>{ dropUserName()}}
            className="bg-red-500 text-white py-2 px-6 rounded-lg hover:bg-red-600 transition shadow-md"
          >
            Sair do sistema
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeAnswer;
