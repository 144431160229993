import { useEffect } from "react";

const VideoButton = ({
  localFeedEl,
  callStatus,
  localStream,
  updateCallStatus,
  peerConnection,
}) => {
  //handle user clicking on video button
  const startStopVideo = () => {
    const copyCallStatus = { ...callStatus };
    // useCases:
    if (copyCallStatus.videoEnabled) {
      // 1. Video is enabled, so we need to disable
      //disable
      copyCallStatus.videoEnabled = false;
      updateCallStatus(copyCallStatus);
      const tracks = localStream.getVideoTracks();
      tracks.forEach((track) => (track.enabled = false));
    } else if (copyCallStatus.videoEnabled === false) {
      // 2. Video is disabled, so we need to enable
      copyCallStatus.videoEnabled = true;
      updateCallStatus(copyCallStatus);
      const tracks = localStream.getVideoTracks();
      tracks.forEach((track) => (track.enabled = true));
    } else if (copyCallStatus.videoEnabled === null) {
      
        // 3. Video is null, so we need to init
      copyCallStatus.videoEnabled = true;
      updateCallStatus(copyCallStatus);
      // we are not adding tracks so they are visible
      // in the video tag. We are addign them
      // to the PC, so they can be sent
      localStream.getTracks().forEach((track) => {
        peerConnection.addTrack(track, localStream);
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const videoButton = document.getElementById('start-video');
      if (videoButton) {
        videoButton.click(); // Trigger the click
      }
    }, 1000); // 1 second delay

    // Cleanup in case the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="button-wrapper video-button d-inline-block">
      <i className="fa fa-caret-up choose-video"></i>
      <div className="button camera" onClick={startStopVideo} id="start-video">
        <i className="fa fa-video"></i>
        <div className="btn-text">
          {callStatus.videoEnabled ? "Parar" : "Iniciar"} Video
        </div>
      </div>
    </div>
  );
};
export default VideoButton;
