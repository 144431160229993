import { useState, useEffect } from "react";

const AudioButton = ({
  callStatus,
  updateCallStatus,
  localStream,
  peerConnection,
}) => {
  let micText;
    console.log('callStatus.audioEnabled', callStatus.audioEnabled);
    
  if (!callStatus.audioEnabled) {
    micText = "Habilitar";
  } else {
    micText = "Mutar";
  }

  const startStopAudio = () => {
    const copyCallStatus = { ...callStatus };
    //first, check if the audio is enabled, if so disabled
    if (callStatus.audioEnabled === true) {
      //update redux callStatus
      copyCallStatus.audioEnabled = false;
      updateCallStatus(copyCallStatus);
      //set the stream to disabled
      const tracks = localStream.getAudioTracks();
      tracks.forEach((t) => (t.enabled = false));
    } else if (callStatus.audioEnabled === false) {
      //second, check if the audio is disabled, if so enable
      //update redux callStatus
      copyCallStatus.audioEnabled = true;
      updateCallStatus(copyCallStatus);
      const tracks = localStream.getAudioTracks();
      tracks.forEach((t) => (t.enabled = true));
    } else {
      //audio is "off" What do we do?
      // changeAudioDevice({target:{value:"inputdefault"}})
      //add the tracks
      localStream.getAudioTracks().forEach((t) => {
        peerConnection.addTrack(t, localStream);
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
        const audioButton = document.getElementById('start-audio');
        if (audioButton) {
          audioButton.click(); // Trigger the click
        }
      }, 1000); // 1 second delay
  
      // Cleanup in case the component unmounts before the timeout
      return () => clearTimeout(timer);

  }, [])


  return (
    <div className="button-wrapper d-inline-block">
      <i className="fa fa-caret-up choose-audio"></i>
      <div className="button mic" onClick={startStopAudio} id="start-audio">
        <i className="fa fa-microphone"></i>
        <div className="btn-text">{micText}</div>
      </div>
    </div>
  );
};

export default AudioButton;
